import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Container, Row, Col } from "react-bootstrap";

export const Contact = withWrapper(() => {
  useTitle("Contact Us");

  return (
    <Container>
      <h1>Contact Us</h1>

      <Row>
        <Col md={8} lg={6}>
          <p>
            Thank you for using Sparta, your ultimate at-home training solution.
            Whether you are a Spartan looking for guidance or a trainer wanting
            to share your expertise, we are here to help!
          </p>
          <p>
            For any questions, feedback, or support, please send us an{" "}
            <a href="mailto:idoyana@gmail.com">email</a>.
          </p>
          <p>Stay strong and keep training,</p>
        </Col>
      </Row>
    </Container>
  );
});
