"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedbackState = void 0;
var FeedbackState;
(function (FeedbackState) {
    FeedbackState["Done"] = "Done";
    FeedbackState["InProgress"] = "In Progress";
    FeedbackState["Open"] = "Open";
    FeedbackState["Pending"] = "Pending";
    FeedbackState["Reopened"] = "Reopened";
})(FeedbackState || (exports.FeedbackState = FeedbackState = {}));
