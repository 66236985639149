"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MuscleArea = void 0;
var MuscleArea;
(function (MuscleArea) {
    MuscleArea["Core"] = "Core";
    MuscleArea["FullBody"] = "Full Body";
    MuscleArea["LowerBody"] = "Lower Body";
    MuscleArea["UpperBody"] = "Upper Body";
})(MuscleArea || (exports.MuscleArea = MuscleArea = {}));
