"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subject = void 0;
var Subject;
(function (Subject) {
    // Arenas
    Subject["ArenaEntity"] = "ArenaEntity";
    Subject["ArenaSubscriptionEntity"] = "ArenaSubscriptionEntity";
    Subject["ProgramEntity"] = "ProgramEntity";
    Subject["ProgramSubscriptionEntity"] = "ProgramSubscriptionEntity";
    // Authentication
    Subject["AccountEntity"] = "AccountEntity";
    Subject["UserEntity"] = "UserEntity";
    // Core
    Subject["FeedbackEntity"] = "FeedbackEntity";
    // Exercises
    Subject["ExerciseEntity"] = "ExerciseEntity";
    Subject["ExerciseGroupEntity"] = "ExerciseGroupEntity";
    Subject["ExerciseNoteEntity"] = "ExerciseNoteEntity";
    Subject["StretchEntity"] = "StretchEntity";
    // Monetization
    Subject["PlanPaymentMethodEntity"] = "PlanPaymentMethodEntity";
    Subject["PlanPaymentEntity"] = "PlanPaymentEntity";
    Subject["PlanSubscriptionEntity"] = "PlanSubscriptionEntity";
    Subject["PlanEntity"] = "PlanEntity";
    // Workouts
    Subject["WorkoutEntity"] = "WorkoutEntity";
    Subject["WorkoutExerciseEntity"] = "WorkoutExerciseEntity";
    Subject["WorkoutExerciseLevelEntity"] = "WorkoutExerciseLevelEntity";
    Subject["WorkoutHistoryEntity"] = "WorkoutHistoryEntity";
    Subject["UserWorkoutEntity"] = "UserWorkoutEntity";
})(Subject || (exports.Subject = Subject = {}));
