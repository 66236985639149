"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudienceGroup = void 0;
var AudienceGroup;
(function (AudienceGroup) {
    AudienceGroup["Adaptive"] = "Adaptive";
    AudienceGroup["Everyone"] = "Everyone";
    AudienceGroup["PostPregnancy"] = "Post Pregnancy";
    AudienceGroup["Seniors"] = "Seniors";
    AudienceGroup["Youth"] = "Youth";
})(AudienceGroup || (exports.AudienceGroup = AudienceGroup = {}));
