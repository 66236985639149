import { PublicLayout } from "modules/common";
import {
  Contact,
  FAQ,
  Home,
  NotFound,
  Privacy,
  ServerError,
  Terms,
} from "modules/core";

const publicRoutes = [
  { path: "/", element: <Home /> },

  // trust
  { path: "privacy", element: <Privacy /> },
  { path: "terms", element: <Terms /> },

  // help
  { path: "faq", element: <FAQ /> },
  { path: "contact", element: <Contact /> },

  // error pages
  { path: "4xx-error", element: <NotFound /> },
  { path: "5xx-error", element: <ServerError /> },

  // not found routes
  { path: "*", element: <NotFound /> },
];

const get = () => [
  {
    path: "/",
    element: <PublicLayout />,
    children: publicRoutes,
  },
];

export { get };
