"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MuscleGroup = void 0;
var MuscleGroup;
(function (MuscleGroup) {
    MuscleGroup["Abs"] = "Abs";
    MuscleGroup["Back"] = "Back";
    MuscleGroup["Biceps"] = "Biceps";
    MuscleGroup["Calves"] = "Calves";
    MuscleGroup["Chest"] = "Chest";
    MuscleGroup["Forearms"] = "Forearms";
    MuscleGroup["Glutes"] = "Glutes";
    MuscleGroup["Hamstrings"] = "Hamstrings";
    MuscleGroup["Quadriceps"] = "Quadriceps";
    MuscleGroup["Shoulders"] = "Shoulders";
    MuscleGroup["Triceps"] = "Triceps";
})(MuscleGroup || (exports.MuscleGroup = MuscleGroup = {}));
