import { Outlet } from "react-router-dom";
import { SharedLayout } from "./SharedLayout";

export const PublicLayout = () => {
  return (
    <SharedLayout>
      <Outlet />
    </SharedLayout>
  );
};
