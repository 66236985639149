import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Accordion, Card, Container } from "react-bootstrap";

export const FAQ = withWrapper(() => {
  useTitle("FAQ");

  return (
    <Container className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>What is Sparta?</Accordion.Header>
          <Accordion.Body>
            Sparta is an app that allows users to train at home by subscribing
            to workout programs uploaded by trainers from all over the world.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header as={Card.Header}>
            How do I subscribe to a workout program?
          </Accordion.Header>
          <Accordion.Body>
            You can subscribe to workout programs within the app by browsing the
            arenas that interest you and selecting a program to subscribe to.
            Payments are handled through in-app purchases.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header as={Card.Header}>What are arenas?</Accordion.Header>
          <Accordion.Body>
            Arenas are sections within the app that cater to specific target
            audiences. Each arena contains multiple programs that Spartans
            (users) can subscribe to.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header as={Card.Header}>
            How can trainers upload their workouts?
          </Accordion.Header>
          <Accordion.Body>
            Trainers can upload their workouts through a dedicated admin site
            where they can manage their content and programs.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header as={Card.Header}>
            Does Sparta support multiple languages?
          </Accordion.Header>
          <Accordion.Body>
            Yes, Sparta supports 57 languages for both display and speech. You
            can choose your preferred language in the app settings.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header as={Card.Header}>
            Can I listen to music while working out?
          </Accordion.Header>
          <Accordion.Body>
            Yes, Sparta integrates with digital radio channels using m3u8,
            allowing you to listen to music while you train.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header as={Card.Header}>
            What are notes in the app?
          </Accordion.Header>
          <Accordion.Body>
            Notes are additional information or instructions that can be shown
            as subtitles or read out loud during your workout sessions.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
});
