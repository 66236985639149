"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteDisplayInterval = void 0;
var NoteDisplayInterval;
(function (NoteDisplayInterval) {
    NoteDisplayInterval["EverySet"] = "Every Set";
    NoteDisplayInterval["OnceWeek"] = "Once a Week";
    NoteDisplayInterval["OnceMonth"] = "Once a Month";
    NoteDisplayInterval["Once"] = "Once";
    NoteDisplayInterval["Never"] = "Never";
})(NoteDisplayInterval || (exports.NoteDisplayInterval = NoteDisplayInterval = {}));
