"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkoutBase = exports.Target = exports.StretchType = exports.NoteDisplayInterval = exports.NoteDisplay = exports.MuscleGroup = exports.MuscleArea = exports.Level = exports.Gender = exports.ExerciseType = exports.Equipment = exports.AudienceGroup = exports.TranslatableEnums = void 0;
__exportStar(require("./account-type.const"), exports);
__exportStar(require("./action.const"), exports);
__exportStar(require("./content-state.const"), exports);
__exportStar(require("./day.const"), exports);
__exportStar(require("./entity-state.const"), exports);
__exportStar(require("./event.const"), exports);
__exportStar(require("./feature-type.const"), exports);
__exportStar(require("./feedback-state.const"), exports);
__exportStar(require("./language.const"), exports);
__exportStar(require("./limitation.const"), exports);
__exportStar(require("./module.const"), exports);
__exportStar(require("./payment-type.const"), exports);
__exportStar(require("./payment-vendor.const"), exports);
__exportStar(require("./plan-subscription-state.const"), exports);
__exportStar(require("./plan-type.const"), exports);
__exportStar(require("./role.const"), exports);
__exportStar(require("./scope.const"), exports);
__exportStar(require("./start-week-on.const"), exports);
__exportStar(require("./subject.const"), exports);
__exportStar(require("./unit-system.const"), exports);
__exportStar(require("./week.const"), exports);
__exportStar(require("./workout-cycle.const"), exports);
__exportStar(require("./z-index-levels.const"), exports);
// Translatable enums
const audience_group_const_1 = require("./audience-group.const");
Object.defineProperty(exports, "AudienceGroup", { enumerable: true, get: function () { return audience_group_const_1.AudienceGroup; } });
const equipment_const_1 = require("./equipment.const");
Object.defineProperty(exports, "Equipment", { enumerable: true, get: function () { return equipment_const_1.Equipment; } });
const exercise_type_const_1 = require("./exercise-type.const");
Object.defineProperty(exports, "ExerciseType", { enumerable: true, get: function () { return exercise_type_const_1.ExerciseType; } });
const gender_const_1 = require("./gender.const");
Object.defineProperty(exports, "Gender", { enumerable: true, get: function () { return gender_const_1.Gender; } });
const level_const_1 = require("./level.const");
Object.defineProperty(exports, "Level", { enumerable: true, get: function () { return level_const_1.Level; } });
const muscle_area_const_1 = require("./muscle-area.const");
Object.defineProperty(exports, "MuscleArea", { enumerable: true, get: function () { return muscle_area_const_1.MuscleArea; } });
const muscle_group_const_1 = require("./muscle-group.const");
Object.defineProperty(exports, "MuscleGroup", { enumerable: true, get: function () { return muscle_group_const_1.MuscleGroup; } });
const note_display_const_1 = require("./note-display.const");
Object.defineProperty(exports, "NoteDisplay", { enumerable: true, get: function () { return note_display_const_1.NoteDisplay; } });
const note_display_interval_const_1 = require("./note-display-interval.const");
Object.defineProperty(exports, "NoteDisplayInterval", { enumerable: true, get: function () { return note_display_interval_const_1.NoteDisplayInterval; } });
const stretch_type_const_1 = require("./stretch-type.const");
Object.defineProperty(exports, "StretchType", { enumerable: true, get: function () { return stretch_type_const_1.StretchType; } });
const target_const_1 = require("./target.const");
Object.defineProperty(exports, "Target", { enumerable: true, get: function () { return target_const_1.Target; } });
const workout_base_const_1 = require("./workout-base.const");
Object.defineProperty(exports, "WorkoutBase", { enumerable: true, get: function () { return workout_base_const_1.WorkoutBase; } });
exports.TranslatableEnums = {
    AudienceGroup: audience_group_const_1.AudienceGroup,
    Equipment: equipment_const_1.Equipment,
    ExerciseType: exercise_type_const_1.ExerciseType,
    Gender: gender_const_1.Gender,
    Level: level_const_1.Level,
    MuscleArea: muscle_area_const_1.MuscleArea,
    MuscleGroup: muscle_group_const_1.MuscleGroup,
    NoteDisplay: note_display_const_1.NoteDisplay,
    NoteDisplayInterval: note_display_interval_const_1.NoteDisplayInterval,
    StretchType: stretch_type_const_1.StretchType,
    Target: target_const_1.Target,
    WorkoutBase: workout_base_const_1.WorkoutBase,
};
