"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DayOrder = exports.Day = void 0;
var Day;
(function (Day) {
    Day["Sunday"] = "Sunday";
    Day["Monday"] = "Monday";
    Day["Tuesday"] = "Tuesday";
    Day["Wednesday"] = "Wednesday";
    Day["Thursday"] = "Thursday";
    Day["Friday"] = "Friday";
    Day["Saturday"] = "Saturday";
})(Day || (exports.Day = Day = {}));
exports.DayOrder = {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
};
