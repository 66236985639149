import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Button, Col, Container, Row, Image } from "react-bootstrap";

export const Home = withWrapper(() => {
  useTitle("Home");

  return (
    <Container>
      <h1 className="text-center my-4">Welcome to Sparta</h1>
      <p className="text-center">
        Train at home with top trainers from around the world. Join arenas,
        subscribe to programs, and achieve your fitness goals with personalized
        guidance.
      </p>
      <Row className="my-4">
        <Col md={6} className="text-center">
          <Image src={`${process.env.PUBLIC_URL}/images/store.png`} fluid />
          <h3 className="mt-3">Explore the Store</h3>
          <p>
            Discover a wide variety of workouts uploaded by trainers from across
            the globe. Browse through different programs in the store and find
            the one that suits your fitness goals. With Sparta, the power to
            transform is at your fingertips.
          </p>
        </Col>
        <Col md={6} className="text-center">
          <h3 className="mt-3">Download Sparta</h3>
          <p>Get the app and start your journey with Sparta.</p>
          <Row>
            <Col>
              <Button
                className="d-block mx-auto my-2"
                href="https://play.google.com/store/apps/details?id=io.spartapp"
                target="_blank"
                variant="primary"
              >
                <img
                  alt="Download on Google Play"
                  className="mx-2"
                  src={`${process.env.PUBLIC_URL}/images/googlePlay.png`}
                  style={{ width: "20px", height: "20px" }}
                />
                Google Play
              </Button>
            </Col>
            <Col>
              <Button
                className="d-block mx-auto my-2"
                href="https://apps.apple.com/app/id6502325154"
                target="_blank"
                variant="primary"
              >
                <img
                  alt="Download on the App Store"
                  className="mx-2"
                  src={`${process.env.PUBLIC_URL}/images/appStore.png`}
                  style={{ width: "20px", height: "20px" }}
                />
                App Store
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="text-center">
          <Image src={`${process.env.PUBLIC_URL}/images/notes.png`} fluid />
          <h3 className="mt-3">Workout Notes</h3>
          <p>
            Stay informed and motivated with workout notes that can be displayed
            as subtitles or spoken aloud. Sparta supports 57 languages, ensuring
            you understand every instruction, no matter where you are from.
          </p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="text-center">
          <Image src={`${process.env.PUBLIC_URL}/images/trainers.png`} fluid />
          <h3 className="mt-3">Global Trainer Community</h3>
          <p>
            Our app features trainers from around the world, bringing diverse
            training styles and expertise to your home. Subscribe to your
            favorite trainers and follow their programs. Experience the
            difference of personalized training.
          </p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col className="text-center">
          <Image src={`${process.env.PUBLIC_URL}/images/radio.png`} fluid />
          <h3 className="mt-3">Digital Radio Integration</h3>
          <p>
            Enjoy training with the best music from integrated digital radio
            channels. Boost your workouts with the perfect soundtrack.
          </p>
        </Col>
      </Row>
    </Container>
  );
});
