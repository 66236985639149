"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Target = void 0;
var Target;
(function (Target) {
    Target["WeightLoss"] = "Weight Loss";
    Target["MuscleGain"] = "Muscle Gain";
    Target["Endurance"] = "Endurance";
    Target["Flexibility"] = "Flexibility";
    Target["Cardio"] = "Cardio";
    Target["Mindfulness"] = "Mindfulness";
})(Target || (exports.Target = Target = {}));
