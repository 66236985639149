import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Accordion, Container } from "react-bootstrap";

export const Privacy = withWrapper(() => {
  useTitle("Privacy");

  return (
    <Container>
      <h1>Privacy</h1>

      <Accordion alwaysOpen defaultActiveKey="1">
        {/* Section 1: Introduction */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>1. Introduction</Accordion.Header>
          <Accordion.Body>
            Welcome to Sparta! This Privacy Policy outlines how we collect, use,
            and protect your personal information when you use our Sparta app, a
            platform designed for home workouts and fitness training.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 2: Data Collection */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>2. Data Collection</Accordion.Header>
          <Accordion.Body>
            <strong>2.1. User Information:</strong> When you sign up for Sparta
            using Google, we collect your email and name.
            <br />
            <strong>2.2. Trainer Profiles:</strong> If you are a trainer using
            Sparta, you will provide details including your professional
            background, videos of exercises, and the workout programs you
            create.
            <br />
            <strong>2.3. Automatic Data Collection:</strong> Our app
            automatically collects data such as your preferred display/speech
            language, device information, and usage patterns to enhance your
            experience.
            <br />
            <strong>2.4. Future Data Collection:</strong> We may collect
            additional data in the future, which will be promptly updated in our
            Privacy Policy.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 3: User Consent & Legal Compliance */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            3. User Consent & Legal Compliance
          </Accordion.Header>
          <Accordion.Body>
            By using Sparta, you agree to our collection and use of your
            information as described in this policy. We comply with GDPR, CCPA,
            and other relevant data protection laws. You have the right to
            access, rectify, or delete your personal data and can withdraw your
            consent at any time.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 4: Use of Your Data */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>4. Use of Your Data</Accordion.Header>
          <Accordion.Body>
            Your data is used to create and manage your account, provide
            personalized workout programs, enable trainers to tailor their
            workouts to your needs, improve app functionality and user
            experience, and communicate important account and service
            information.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 5: Data Sharing */}
        <Accordion.Item eventKey="5">
          <Accordion.Header>5. Data Sharing</Accordion.Header>
          <Accordion.Body>
            We do not sell your data. We may share your information with
            third-party service providers for app functionality, legal
            authorities if required by law, and in case of a business transfer.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 6: Data Retention and International Transfers */}
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            6. Data Retention and International Transfers
          </Accordion.Header>
          <Accordion.Body>
            We retain your personal data as long as your account is active or as
            needed for legal purposes. We may transfer data internationally,
            including to cloud service providers like Cloudinary and our servers
            hosted on Heroku. Both Cloudinary and Heroku act as data processors
            on our behalf, ensuring data protection as per international
            standards.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 7: Data Security */}
        <Accordion.Item eventKey="7">
          <Accordion.Header>7. Data Security</Accordion.Header>
          <Accordion.Body>
            We implement robust security measures to protect your data. However,
            please be aware that no system is completely infallible.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 8: Your Rights */}
        <Accordion.Item eventKey="8">
          <Accordion.Header>8. Your Rights</Accordion.Header>
          <Accordion.Body>
            You have the right to access, correct, or delete your personal data.
            You can delete your profile directly from the app. Please contact us
            to exercise these rights or for any other concerns.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 9: Changes to Privacy Policy */}
        <Accordion.Item eventKey="9">
          <Accordion.Header>9. Changes to Privacy Policy</Accordion.Header>
          <Accordion.Body>
            We may update this policy to reflect changes in our practices. We
            will notify you of any significant changes via email. It is
            important to keep your email address updated in our records.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 10: Technology and Data Handling */}
        <Accordion.Item eventKey="10">
          <Accordion.Header>10. Technology and Data Handling</Accordion.Header>
          <Accordion.Body>
            Our app is developed primarily for mobile use. User data, including
            workout preferences, is stored securely in our database hosted on
            Heroku with PostgreSQL as our database service. Media content is
            managed through Cloudinary, ensuring optimized performance. We use
            GitHub for code hosting and Heroku CI for automated deployment.
            Please note, as a mobile app, we currently do not use cookies for
            data collection or tracking.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 11: Age Restriction */}
        <Accordion.Item eventKey="11">
          <Accordion.Header>11. Age Restriction</Accordion.Header>
          <Accordion.Body>
            Sparta is designed for individuals who are legally able to consent
            and responsible for their own workouts. We do not allow children
            under the age of 18 to use our app. If we discover that a child
            under 18 has provided us with personal information, we will take
            steps to delete such information from our servers immediately.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 12: Consent at Signup */}
        <Accordion.Item eventKey="12">
          <Accordion.Header>12. Consent at Signup</Accordion.Header>
          <Accordion.Body>
            Users agree to this Privacy Policy and provide explicit consent by
            checking a box during the signup process. This active consent
            mechanism ensures compliance with data protection laws like GDPR.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 13: Updates to This Policy */}
        <Accordion.Item eventKey="13">
          <Accordion.Header>13. Updates to This Policy</Accordion.Header>
          <Accordion.Body>
            We reserve the right to modify this policy. Any changes will be
            posted here, and if the changes are significant, we will notify you
            by email. Your continued use of Sparta after any changes indicates
            your acceptance of the new terms.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 14: Contact Us */}
        <Accordion.Item eventKey="14">
          <Accordion.Header>14. Contact Us</Accordion.Header>
          <Accordion.Body>
            For any questions or concerns about this policy, please contact us
            at <a href="mailto:idoyana@gmail.com">idoyana@gmail.com</a>.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="my-2">Effective as of: 01 Jan 2024</div>
    </Container>
  );
});
