"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountType = void 0;
var AccountType;
(function (AccountType) {
    AccountType["Admin"] = "Admin";
    AccountType["Test"] = "Test";
    AccountType["Trainer"] = "Trainer";
    AccountType["User"] = "User";
})(AccountType || (exports.AccountType = AccountType = {}));
