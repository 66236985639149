"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlanSubscriptionState = void 0;
var PlanSubscriptionState;
(function (PlanSubscriptionState) {
    // User is in good standing and has access to the subscription.
    PlanSubscriptionState["Active"] = "Active";
    // User has cancelled but still has access until expiration.
    PlanSubscriptionState["Cancelled"] = "Cancelled";
    // User experienced a payment issue but still has access while Google is retrying the payment method.
    PlanSubscriptionState["GracePeriod"] = "GracePeriod";
    // User experienced a payment issue and no longer has access while Google is retrying the payment method.
    PlanSubscriptionState["OnHold"] = "OnHold";
    // User paused their access and does not have access until they resume.
    PlanSubscriptionState["Paused"] = "Paused";
    // User has cancelled and lost access to the subscription. The user is considered churned at expiration.
    PlanSubscriptionState["Expired"] = "Expired";
})(PlanSubscriptionState || (exports.PlanSubscriptionState = PlanSubscriptionState = {}));
