import { useAppDispatch } from "app/hooks";
import { routesGetter, startupScripts } from "modules";
import { SpinnerLoader } from "modules/common";
import { Suspense, useEffect, useMemo } from "react";
import { useRoutes } from "react-router-dom";

const AppContent = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(startupScripts.load());
  }, []);

  const routes = useMemo(() => routesGetter.get(), []);
  return useRoutes(routes);
};

export const App = () => {
  return (
    <Suspense fallback={<SpinnerLoader />}>
      <AppContent />
    </Suspense>
  );
};
