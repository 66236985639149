import { withWrapper } from "app/hooks";
import { useTitle } from "modules/common";
import { Accordion, Container } from "react-bootstrap";

export const Terms = withWrapper(() => {
  useTitle("Terms");

  return (
    <Container>
      <h1>Terms and Conditions</h1>

      <Accordion alwaysOpen defaultActiveKey="1">
        {/* Section 1: Introduction */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>1. Introduction</Accordion.Header>
          <Accordion.Body>
            Welcome to Sparta, a mobile platform designed for home workouts.
            Sparta allows trainers from around the world to upload their
            workouts, and users (Spartans) can subscribe to these programs. The
            platform includes arenas with targeted audiences, integration with
            digital radio channels, and multilingual support.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 2: Registration and Account */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>2. Registration and Account</Accordion.Header>
          <Accordion.Body>
            User registration and account management are exclusively done
            through the app using Google sign-in. Trainers have a dedicated
            admin section for managing their content and profiles.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 3: User Conduct */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>3. User Conduct</Accordion.Header>
          <Accordion.Body>
            Users are permitted to use Sparta for personal fitness purposes
            only. Any commercial or unauthorized use of the platform is strictly
            prohibited. Users must adhere to community guidelines and respect
            the intellectual property of trainers and other users.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 4: Content and Intellectual Property */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            4. Content and Intellectual Property
          </Accordion.Header>
          <Accordion.Body>
            All content, including workouts and notes provided by trainers, is
            visible to app users. Users should exercise discretion and consult
            professionals when following workout advice. Sparta retains the
            right to remove any content that violates our terms or policies.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 5: Subscription and Payments */}
        <Accordion.Item eventKey="5">
          <Accordion.Header>5. Subscription and Payments</Accordion.Header>
          <Accordion.Body>
            Sparta operates on a subscription model managed through in-app
            purchases via Google Play and the Apple App Store. Users can
            subscribe to various programs offered by trainers within different
            arenas. All payments are handled securely through these platforms.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 6: Privacy Policy */}
        <Accordion.Item eventKey="6">
          <Accordion.Header>6. Privacy Policy</Accordion.Header>
          <Accordion.Body>
            Please refer to our{" "}
            <a href="/privacy" target="_blank">
              privacy policy
            </a>{" "}
            for detailed information on how we handle and protect your data. We
            only collect your email and name during the registration process.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 7: Changes to Terms */}
        <Accordion.Item eventKey="7">
          <Accordion.Header>7. Changes to Terms</Accordion.Header>
          <Accordion.Body>
            We may update these terms from time to time. Significant changes
            will be communicated to users via email. Continued use of the
            platform after such changes indicates acceptance of the new terms.
          </Accordion.Body>
        </Accordion.Item>

        {/* Section 8: Contact Information */}
        <Accordion.Item eventKey="8">
          <Accordion.Header>8. Contact Information</Accordion.Header>
          <Accordion.Body>
            For any inquiries or concerns, please contact us at [your contact
            email].
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="my-2">Last updated: 01 Jan 2024</div>
    </Container>
  );
});
