"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekOrder = exports.Week = void 0;
var Week;
(function (Week) {
    Week["First"] = "First";
    Week["Second"] = "Second";
})(Week || (exports.Week = Week = {}));
exports.WeekOrder = {
    First: 1,
    Second: 2,
};
