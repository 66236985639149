import { useCallback } from "react";

interface Props {
  error: Error;
}

export const ErrorFallback = ({ error }: Props) => {
  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={refreshPage}>Try again</button>
    </div>
  );
};
