"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
var Arena;
(function (Arena) {
    Arena["Created"] = "created";
})(Arena || (Arena = {}));
var Exercise;
(function (Exercise) {
    Exercise["Created"] = "created";
    Exercise["Updated"] = "updated";
    Exercise["Deleted"] = "deleted";
})(Exercise || (Exercise = {}));
var ExerciseNote;
(function (ExerciseNote) {
    ExerciseNote["Created"] = "created";
    ExerciseNote["Updated"] = "updated";
    ExerciseNote["Deleted"] = "deleted";
})(ExerciseNote || (ExerciseNote = {}));
var Stretch;
(function (Stretch) {
    Stretch["Created"] = "created";
    Stretch["Updated"] = "updated";
    Stretch["Deleted"] = "deleted";
})(Stretch || (Stretch = {}));
var Subscription;
(function (Subscription) {
    Subscription["GoogleNotification"] = "googleNotification";
})(Subscription || (Subscription = {}));
var Workout;
(function (Workout) {
    Workout["Created"] = "created";
    Workout["Updated"] = "updated";
})(Workout || (Workout = {}));
var WorkoutExercise;
(function (WorkoutExercise) {
    WorkoutExercise["Created"] = "created";
    WorkoutExercise["Updated"] = "updated";
    WorkoutExercise["Deleted"] = "deleted";
})(WorkoutExercise || (WorkoutExercise = {}));
function prefixEnum(prefix, enumObj) {
    return new Proxy(enumObj, {
        get(target, prop) {
            return `${prefix}.${target[prop]}`;
        },
    });
}
exports.Event = {
    Arena: prefixEnum("arena", Arena),
    Exercise: prefixEnum("exercise", Exercise),
    ExerciseNote: prefixEnum("exerciseNote", ExerciseNote),
    Stretch: prefixEnum("stretch", Stretch),
    Subscription: prefixEnum("subscription", Subscription),
    Workout: prefixEnum("workout", Workout),
    WorkoutExercise: prefixEnum("workoutExercise", WorkoutExercise),
};
