"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteDisplay = void 0;
var NoteDisplay;
(function (NoteDisplay) {
    NoteDisplay["Audio"] = "Audio";
    NoteDisplay["Text"] = "Text";
    NoteDisplay["Both"] = "Both";
    NoteDisplay["None"] = "None";
})(NoteDisplay || (exports.NoteDisplay = NoteDisplay = {}));
