"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Language = void 0;
var Language;
(function (Language) {
    Language["Afrikaans"] = "Afrikaans";
    Language["Arabic"] = "Arabic";
    Language["Armenian"] = "Armenian";
    Language["Azerbaijani"] = "Azerbaijani";
    Language["Belarusian"] = "Belarusian";
    Language["Bosnian"] = "Bosnian";
    Language["Bulgarian"] = "Bulgarian";
    Language["Catalan"] = "Catalan";
    Language["Chinese"] = "Chinese";
    Language["Croatian"] = "Croatian";
    Language["Czech"] = "Czech";
    Language["Danish"] = "Danish";
    Language["Dutch"] = "Dutch";
    Language["English"] = "English";
    Language["Estonian"] = "Estonian";
    Language["Finnish"] = "Finnish";
    Language["French"] = "French";
    Language["Galician"] = "Galician";
    Language["German"] = "German";
    Language["Greek"] = "Greek";
    Language["Hebrew"] = "Hebrew";
    Language["Hindi"] = "Hindi";
    Language["Hungarian"] = "Hungarian";
    Language["Icelandic"] = "Icelandic";
    Language["Indonesian"] = "Indonesian";
    Language["Italian"] = "Italian";
    Language["Japanese"] = "Japanese";
    Language["Kannada"] = "Kannada";
    Language["Kazakh"] = "Kazakh";
    Language["Korean"] = "Korean";
    Language["Latvian"] = "Latvian";
    Language["Lithuanian"] = "Lithuanian";
    Language["Macedonian"] = "Macedonian";
    Language["Malay"] = "Malay";
    Language["Maori"] = "Maori";
    Language["Marathi"] = "Marathi";
    Language["Nepali"] = "Nepali";
    Language["Norwegian"] = "Norwegian";
    Language["Persian"] = "Persian";
    Language["Polish"] = "Polish";
    Language["Portuguese"] = "Portuguese";
    Language["Romanian"] = "Romanian";
    Language["Russian"] = "Russian";
    Language["Serbian"] = "Serbian";
    Language["Slovak"] = "Slovak";
    Language["Slovenian"] = "Slovenian";
    Language["Spanish"] = "Spanish";
    Language["Swahili"] = "Swahili";
    Language["Swedish"] = "Swedish";
    Language["Tagalog"] = "Tagalog";
    Language["Tamil"] = "Tamil";
    Language["Thai"] = "Thai";
    Language["Turkish"] = "Turkish";
    Language["Ukrainian"] = "Ukrainian";
    Language["Urdu"] = "Urdu";
    Language["Vietnamese"] = "Vietnamese";
    Language["Welsh"] = "Welsh";
})(Language || (exports.Language = Language = {}));
