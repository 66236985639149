"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Equipment = void 0;
var Equipment;
(function (Equipment) {
    Equipment["AerobicStep"] = "Aerobic Step";
    Equipment["AnkleWeights"] = "Ankle Weights";
    Equipment["Bench"] = "Bench";
    Equipment["BosuBall"] = "Bosu Ball";
    Equipment["Chair"] = "Chair";
    Equipment["Dumbbell"] = "Dumbbell";
    Equipment["FoamRoller"] = "Foam Roller";
    Equipment["JumpRope"] = "Jump Rope";
    Equipment["Kettlebell"] = "Kettlebell";
    Equipment["MedicineBall"] = "Medicine Ball";
    Equipment["MiniTrampoline"] = "Mini Trampoline";
    Equipment["PilatesRing"] = "Pilates Ring";
    Equipment["PullUpBar"] = "Pull-Up Bar";
    Equipment["PushUpBars"] = "Push-Up Bars";
    Equipment["ResistanceBand"] = "Resistance Band";
    Equipment["StabilityBall"] = "Stability Ball";
    Equipment["Towel"] = "Towel";
    Equipment["YogaMat"] = "Yoga Mat";
})(Equipment || (exports.Equipment = Equipment = {}));
