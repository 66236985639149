"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Module = void 0;
var Module;
(function (Module) {
    Module["App"] = "app";
    Module["Arena"] = "arena";
    Module["Authentication"] = "authentication";
    Module["Authorization"] = "authorization";
    Module["Common"] = "common";
    Module["Core"] = "core";
    Module["Enums"] = "enums";
    Module["Exercise"] = "exercise";
    Module["Limitation"] = "limitation";
    Module["Media"] = "media";
    Module["Monetization"] = "monetization";
    Module["Profile"] = "profile";
    Module["Store"] = "store";
    Module["Workout"] = "workout";
})(Module || (exports.Module = Module = {}));
