import { withWrapper } from "app/hooks";
import { Logo } from "assets";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarCollapse,
} from "react-bootstrap";
import { NavLink as NavLinkRouter } from "react-router-dom";

export const Header = withWrapper(() => {
  return (
    <Navbar className="bg-body-tertiary mb-3" expand="sm" sticky="top">
      <Container fluid="md">
        <NavbarBrand href="/">
          <img src={Logo} alt="logo" style={{ height: 40, width: 40 }} /> Sparta
        </NavbarBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <NavbarCollapse id="basic-navbar-nav">
          <Nav className="me-auto" variant="underline">
            <NavItem>
              <NavLink as={NavLinkRouter} to="/">
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink as={NavLinkRouter} to="/privacy">
                Privacy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink as={NavLinkRouter} to="/terms">
                Terms
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink as={NavLinkRouter} to="/faq">
                FAQ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink as={NavLinkRouter} to="/contact">
                Contact Us
              </NavLink>
            </NavItem>
          </Nav>
        </NavbarCollapse>
      </Container>
    </Navbar>
  );
});
